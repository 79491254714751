/* imported from ../../../../utils/styles/variables.less  */

/** ignore meepshop id */
@import '../../../../utils/styles/~antd/lib/style/themes/default.less';
@text-color: rgba(0, 0, 0, 0.65);
@border-radius: 4px;
@border-radius-base: 4px;
@border-color-split: #e8e8e8;

@btn-text-shadow: none;
@btn-shadow: none;
@btn-primary-shadow: none;

@checkbox-check-bg: transparent;

/* imported from index.less  */

#meepshop {

.meepshop-divider__index__root {
  display: flex;
  width: 100%;

  &.meepshop-divider__index__FLEX_START {
    justify-content: flex-start;
  }

  &.meepshop-divider__index__CENTER {
    justify-content: center;
  }

  &.meepshop-divider__index__FLEX_END {
    justify-content: flex-end;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";